import { api } from './api';

export const getUsersPaginated = (page: number, pageSize: number, searchText: string) => {
  return api.get(`/v1/user/paginated?page=${page}&pageSize=${pageSize}&searchText=${searchText}`);
}

export const createUser = ({ firstName, lastName, email, password, doc, profileId } : {
  firstName: string, lastName: string, email: string, password: string, doc: string, profileId: number
}) => {
  return api.post(`/v1/user`, { firstName, lastName, email, password, doc, profileId });
}

export const updateUser = ({ id, firstName, lastName, email, password, doc, profileId } : {
  id: string, firstName: string, lastName: string, email: string, password: string, doc: string, profileId: number
}) => {
  return api.put(`/v1/user`, { id, firstName, lastName, email, password, doc, profileId });
}

export const getUserById = (userId: string) => {
  return api.get(`/v1/user/${userId}`);
}

export const deleteUser = (userId: string) => {
  return api.delete(`/v1/user/${userId}`);
}
