import { navigate } from 'gatsby';
import React, { useState, useEffect, useMemo } from 'react';
import { getUserById } from '../../../services/user';
import AdminNewUsers from '../usuarios/nuevo';

const EditUser = ({ params: { id } }: {
  params: {
    id: string
  }
}) => {
  const [loading, isLoading] = useState(true);
  const [user, setUser] = useState({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    doc: '',
    profileId: 1
  });

  useEffect(() => {
    isLoading(true);
    fetchDataById();
    isLoading(false);
  }, []);

  const fetchDataById = async () => {
    const dataToUpdate = await getUserById(id);
    if (!dataToUpdate.data) {
      navigate(`/admin/usuarios`);
      return;
    }
    setUser(dataToUpdate.data);
  }

  const adminNewUserComponent = useMemo(() =>
    <AdminNewUsers
      userId={id}
      firstName={user.firstName}
      lastName={user.lastName}
      email={user.email}
      doc={user.doc}
      loading={loading}
    />, [user]);

  return adminNewUserComponent;
}

export default EditUser;
