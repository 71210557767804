import { Col, Row, Card, CardBody, Form, FormGroup, Label, Input, Button, Spinner } from 'reactstrap';
import { createUser, updateUser } from '../../../../services/user';
import { ToastContainer, toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { HeadFC, Link, navigate } from 'gatsby';
import SideNav from '../../components/sidenav';
import AuthContext from '../../contexts/auth';

const AdminNewUsers = ({ userId, firstName, lastName, email, doc, loading }:
  { userId: string, firstName: string, lastName: string, email: string, doc: string, loading: boolean }
) => {
  const [newUser, setNewUser] = useState({
    id: userId,
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    doc: '',
    profileId: 1
  });
  const [submitEnabled, setSubmitEnabled] = useState(false);

  useEffect(() => {
    setNewUser((prevState) => ({ ...prevState, firstName, lastName, email, doc }));
  }, [firstName, lastName, email, doc, loading]);

  useEffect(() => {
    const userIsDefined = newUser.firstName !== '' && newUser.lastName !== '' &&newUser.email !== '' && newUser.password !== '';
    setSubmitEnabled(userIsDefined);
  }, [newUser]);

  const onSubmitCreateOrUpdateUserForm = (e: any) => {
    e.preventDefault();

    toast.promise(userId ? sendUpdateUser : sendCreateUser,
      {
        pending: `${userId ? 'Actualizando': 'Creando'} usuario`,
        success: `Usuario ${userId ? 'Actualizado': 'Creado'} 👌`,
        error: `Error al ${userId ? 'Actualizar': 'Crear'} usuario 🤯`
      });
  }

  const sendUpdateUser = async () => {
    await updateUser(newUser);
    navigate('/admin/usuarios');
  }

  const sendCreateUser = async () => {
    await createUser(newUser);
    navigate('/admin/usuarios');
  }

  return (
    <AuthContext>
      <ToastContainer />
      <div className="admin-container d-flex flex-row w-100">
        <SideNav
          activeMenu='usuarios'
        />
        <Row className="admin-page-content d-flex flex-column align-items-center">
          <Col md={11}>
            <Card className="w-100 p-4 mb-5">
              <CardBody>
                <h4>
                  <Link
                    to="/admin/usuarios"
                  >
                    <i className="bi bi-arrow-left-square-fill"></i>
                  </Link>{" "}
                  {userId ? 'Actualizar' : 'Nuevo'} usuario

                  {loading &&
                  <Spinner
                    color="primary"
                    style={{
                      height: '3rem',
                      width: '3rem'
                    }}
                  >
                    Loading...
                  </Spinner>}
                </h4>
                {((userId && newUser.email !== '') || !userId)
                && <Form className="mt-4" onSubmit={(e) => onSubmitCreateOrUpdateUserForm(e)}>
                  <Row>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="user_firstname" className="mb-2">
                          Nombre
                        </Label>
                        <Input
                          type="text"
                          id="user_firstname"
                          value={newUser.firstName}
                          onChange={(e) => setNewUser((prevState) => ({ ...prevState, firstName: e.target.value }))}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="user_lastname" className="mb-2">
                          Apellido
                        </Label>
                        <Input
                          type="text"
                          id="user_lastname"
                          value={newUser.lastName}
                          onChange={(e) => setNewUser((prevState) => ({ ...prevState, lastName: e.target.value }))}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="user_email" className="mb-2">
                          Correo electrónico
                        </Label>
                        <Input
                          type="email"
                          id="user_email"
                          value={newUser.email}
                          onChange={(e) => setNewUser((prevState) => ({ ...prevState, email: e.target.value }))}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="user_password" className="mb-2">
                          Clave
                        </Label>
                        <Input
                          type="password"
                          id="user_password"
                          value={newUser.password}
                          onChange={(e) => setNewUser((prevState) => ({ ...prevState, password: e.target.value }))}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="user_doc" className="mb-2">
                          CPF
                        </Label>
                        <Input
                          type="text"
                          id="user_doc"
                          value={newUser.doc}
                          onChange={(e) => setNewUser((prevState) => ({ ...prevState, doc: e.target.value }))}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <FormGroup>
                        <Button
                          type="submit"
                          color="success"
                          className="w-100"
                          disabled={!submitEnabled}
                        >
                          {userId ? 'Actualizar' : 'Crear'} usuario
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </AuthContext>
  );
}

export default AdminNewUsers;

export const Head: HeadFC = () => <title>LaBotana : Admin - Nuevo usuario</title>
